@background: #e2cece;
@dot: #303030;
@fontColor: #333;


.container {
    max-width: 1080px;
    min-height: calc(100vh - 45px);
    margin:  0 auto;
    box-shadow: 1px 1px 8px fade(@background, 60%);
    border-radius: 10px;
    margin-top: 45px;
    margin-bottom: 45px;
    overflow: hidden;
}
.info-block-container {
    margin: 30px 0;
    .title {
        height: 28px;
        line-height: 28px;
        color: @fontColor;
        margin-bottom: 20px;
        &::before {
            content: '';
            display: inline-block;
            width: 8px;
            height: 100%;
            background-color: @dot;
            margin-right: 7px;
            vertical-align: top;
        }
    }
    .sub-title {
        color: #666;
        font-size: 0.8em;
    }
    .content-box {
        margin: 0 20px;
        background-color: @background;
        border-radius: 10px;
        padding: 10px 12px;
        font-size: 13px;
        @media screen and (max-width: 770px) {
            .content-item {
                margin: 10px 0 ;
                &.content-list-wrap {
                    margin: 10px 0;
                    .content-list-item {
                        margin-top: 10px;
                        &:first-of-type {
                            margin: 0;
                        }
                    }
                }
            }
        }
        .content-item {
            margin: 10px 0;
            min-height: 16px;
            &:last-of-type {
                margin: 0;
            }
        }
        .item {
            &::before {
                content: '●';
                margin-right: 5px;
                color: @dot;
            }
        }
        img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }
        p {
            margin: 0;
            white-space: pre-wrap;
        }
    }
}

.print {
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    position: fixed;
    bottom: 60px;
    right: 15px;
    background-color: darken(@background, 10%);
    box-shadow: 0 0 10px 1px fade(@background, 90%);
    cursor: pointer;
}
*[title] {
    cursor: pointer;
}
.water-mark {
    display: none;
    width: 100%;
    height: 100%;;
    max-height: 100%;
    text-align: center;
    line-height: 400px;
    font-size: 30px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    h1 {
        transform: rotate(45deg);
        color: rgba(255,255,255,.4);
    }
}
@media print {
    .print {
        display: none;
    }
    .go-top {
        display: none;
    }
    .water-mark {
        display: block;
    }
}

.mail-mask__container {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
    z-index: 100;
    .mask {
        background-color: rgba(1,1,1,.6);
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        backdrop-filter: blur(2px);
    }
    .mask-body {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .inner-body {
            background-color: #fff;
            width: 50%;
            min-height: 100px;
            border-radius: 10px;
            padding: 15px 20px;
            position: relative;
            .mask-body__title {
                font-size: 16px;
                font-weight: normal;
                line-height: 50px;
                border-bottom: 1px solid #eaeaea;
            }
            .mask-body__close {
                position: absolute;
                right: 0;
                top: 0;
                padding: 20px;
                cursor: pointer;
            }
            .mask-body__content {
                padding: 10px 0;
                font-size: 13px;
            }

        }

    }
}

@primary-color: #0070cc;@success-color: #1e8e3e;@info-color: @primary-color;@warning-color: #ffc440;@error-color: #d93026;@processing-color: @primary-color;@text-color: fade(#000, 65%);@text-color-secondary: fade(#000, 45%);@border-color-base: #dedede;@border-color-split: #dedede;@layout-body-background: #fafafa;@font-size-base: 12px;@border-radius-sm: 0;@outline-width: 0;@outline-color: #737373;@background-color-base: hsv(0, 0, 96%);@btn-default-bg: #fafafa;@btn-default-border: #dedede;@radio-button-bg: transparent;@radio-button-checked-bg: transparent;@form-item-margin-bottom: 16px;@input-height-lg: 36px;@input-hover-border-color: #737373;@progress-radius: 0;@table-header-bg: #fafafa;@table-row-hover-bg: #fafafa;@table-padding-vertical: 15px;@tabs-card-gutter: -1px;@tabs-card-tab-active-border-top: 2px solid @primary-color;@switch-color: @success-color;@breadcrumb-base-color: @text-color;@breadcrumb-last-item-color: @text-color-secondary;@slider-handle-border-width: 1px;@slider-handle-shadow: 1px 1px 4px 0 rgba(0,0,0,.13);@slider-track-background-color: @primary-color;@slider-track-background-color-hover: @primary-color;@slider-handle-color: #dedede;@slider-handle-color-hover: #dedede;@slider-handle-color-focus: #dedede;@slider-handle-color-focus-shadow: transparent;@slider-handle-color-tooltip-open: #ddd;@alert-success-border-color: #dff4e5;@alert-success-bg-color: #dff4e5;@alert-info-border-color: #e5f3ff;@alert-info-bg-color: #e5f3ff;@alert-warning-border-color: #fff7db;@alert-warning-bg-color: #fff7db;@alert-error-border-color: #fcebea;@alert-error-bg-color: #fcebea;